@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");

html,
body{
  background-color: #f7f7f7;
}

header {
  margin: 0 auto;
  background-color: light;
}

section{

  border-radius: 20px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0 0 7px rgba(0,0,0,0.5);
  text-align: left!important;
  height: auto;
}

.header-me {
  margin-top:  -100px;
}

.header-me img {

  width: 150px;
}

.left {
  text-align: left!important;
}

.right {
  text-align: right!important;
}

.section {
  text-align: left!important;
}

.full-bg-img2:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  z-index: 2;
  height: 200px;
}

.navbar {
  background-color: transparent;
  opacity: 0.8;
}

.top-nav-collapse {
  background-color: #4285F4;
}

@media only screen and (max-width: 768px) {
  .navbar {
    background-color: black;
  }

  .name, .description, .nav-link, .button_download {
    text-align: center;
    color: black!important;
  }

  .logo-certification {
    width: 50px;
  }

  .logo-education {
    width: 100px;
  }
}

.view {
  height: 100%;
}

a{
  text-decoration: none;
  color: black;
}

a:hover{
  color: darkgray;

}

.a-link:hover {
  border-bottom: 2px solid black;
}

.photo img {
  border-radius: 20px;
}


#mobileshow { display:block; }

@media screen and (min-width: 500px) {
  #mobileshow  { display:none; }
}

.radio-img > input {
  display: none;
}
.radio-img > .image {
  cursor: pointer;
  border: 2px solid black;
}
.radio-img > input:checked + .image {
  border: 2px solid orange;

}

.flag-language img:hover {
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.skill-card:hover {

  transform: scale(1.1);
  box-shadow: 2px 2px 5px grey, -2px -2px 5px grey;
  background: white;
  border-radius: 5px;
}

.card-portfolio:hover {

  transform: scale(1.2);
  box-shadow: 2px 2px 5px grey, -2px -2px 5px grey;
  background: white;
  border-radius: 5px;
}


.card-projet {

  width: 33%;
  height: 40vh;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-projet:hover {

  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  transform: scale(1.1);
  transition: 1s;
}

.card-projet  a {

  text-decoration-line: none;
}

.card-project-bg {
  position: relative;

}

.info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.72);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  padding: 20px;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.card-project-bg:hover .info {
  visibility: visible;
  opacity: 1;
}

#portfolioImage {

  height: 25vh;
  width: 100%;
  position: relative;
}

#portfolioImage img {

  object-fit: cover;
  height: auto;
  max-height: 100%;
  width: 100%;
}

#portfolioImage p {

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.photo {
  border-radius: 19px;
}

.img-block  img{
  object-fit: cover;
  height: 400px;
  width: 100%;
}

.photo-portfolio-card {
  border-radius: 10px;
  object-fit: cover;
}

.certificate-img {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.logo-certification {
  width: 100%;
  height: 150px;
  max-height: 350px;
}

.auth {
  height: 50vh;
}

.radio-img > span:hover {
  color: green;
}

#btn-send {
  margin-right: 100px;
}

.item {
  padding: 10px;
}

.portfolio-img {
  transition: transform .2s;
}

.portfolio-img:hover {
  transform: scale(.9);
}

.shadowPortfolio:hover {
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
  box-shadow: 10px 10px 5px #888888;
}

/*Tooltip*/
.mytooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.mytooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  border: none;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.mytooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.mytooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/*!*Animate BG*!*/
.bg-custom {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(-45deg, yellow 0%, yellow 25%, yellow 51%, #ff357f 100%);
  -webkit-animation: AnimateBG 20s ease infinite;
  animation: AnimateBG 20s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}